<template>
  <div>
    <template v-if="auth.loggedIn || state === 'sign-in'">
      <nav :aria-label="$t.myAccount">
        <ul class="space-y-3">
          <template v-if="auth.loggedIn">
            <li
              v-if="$feature.enableLoyaltyFeatures && user.isLoyaltyMember"
              class="mb-6"
            >
              <vf-link class="mb-6" to="/account/loyalty" variant="quiet">
                {{ $t.memberRewards }}
              </vf-link>
            </li>
            <li>
              <vf-link to="/account/profile" variant="quiet">
                {{ $t.myProfile }}
              </vf-link>
            </li>
            <li>
              <vf-link to="/account/order-history" variant="quiet">
                {{ $t.myOrders }}
              </vf-link>
            </li>
            <li>
              <vf-link to="/account/address" variant="quiet">
                {{ $t.addressBook }}
              </vf-link>
            </li>
            <li>
              <vf-link to="/account/credit-cards" variant="quiet">
                {{ $t.creditCards }}
              </vf-link>
            </li>
            <li>
              <vf-link to="/favorites" variant="quiet">
                {{ $t.favorites }}
              </vf-link>
            </li>
          </template>
          <template v-else>
            <template v-if="$feature.enableLoyaltyFeatures">
              <li>
                <vf-link to="/loyalty" variant="quiet">
                  {{ $t.earnRewards }}
                </vf-link>
              </li>
              <li>
                <vf-link variant="quiet" @click="state = 'sign-up'">
                  {{ $t.joinLoyaltyCta }}
                </vf-link>
              </li>
            </template>
            <li>
              <vf-link to="/order-status" variant="quiet">
                {{ $t.orderStatus }}
              </vf-link>
            </li>
          </template>
        </ul>
      </nav>
      <vf-button v-if="auth.loggedIn" class="mt-16 w-full" size="xs" variant="secondary" @click="auth.logout">
        {{ $t.signOut }}
      </vf-button>
      <form-sign-in
        v-else
        class="mt-10"
        :form-location="formLocation"
        inside-dialog
        prevent-dialog
        show-sign-up
        @forgot-password="state = 'forgot-password'"
        @sign-up="openSignup"
      />
    </template>
    <template v-else>
      <form-sign-up
        v-if="state === 'sign-up'"
        :e-spot="signupEspot"
        :form-location="formLocation"
        inside-dialog
        prevent-dialog
        style="display: block; padding-bottom: 4.5rem"
        @sign-in="state = 'sign-in'"
      />
      <form-forgot-password
        v-else-if="state === 'forgot-password'"
        inside-dialog
        prevent-dialog
        @sign-in="state = 'sign-in'"
      />
    </template>
  </div>
</template>

<script setup lang="ts">
import type { FormLocation } from '#types/gtm'

defineProps<{
  formLocation?: FormLocation
}>()

const auth = useAuthStore()
const user = useUserStore()
const { getSections } = useCms()

const signupEspot = ref()
const state = ref('sign-in')

const openSignup = async () => {
  state.value = 'sign-up'
  const eSpots = (await getSections.signUpModal()).data.value?.content.sectionsMap
  signupEspot.value = eSpots?.['below-sign-up']
}
</script>
